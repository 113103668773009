<template>
  <div class="d-flex flex-column align-items-center justify-content-center">
    <h1 class="mb-4">
      {{ success ? '恭喜！' : 'Oops!' }}
    </h1>
    <h4
      v-if="message"
      class="mb-5"
    >
      {{ message }}
    </h4>
    <div>
      <router-link
        to="/"
        class="btn btn-primary text-white rounded-0 mx-2"
      >
        回首頁看看
      </router-link>
      <router-link
        to="/product/list"
        class="btn btn-primary text-white rounded-0 mx-2"
      >
        馬上來去逛逛
      </router-link>
      <router-link
        to="/login"
        class="btn btn-primary text-white rounded-0 mx-2"
      >
        登入去
      </router-link>
    </div>
  </div>
</template>
<script>
import { ref, toRef } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'AccountActive',
  components: {

  },
  props: {
    clientID: {
      type: String,
      default: ''
    },
    memberID: {
      type: String,
      default: ''
    }
  },
  async setup (props) {
    const { dispatch } = useStore()

    const clientID = toRef(props, 'clientID')
    const memberID = toRef(props, 'memberID')
    console.log(clientID)

    const success = ref(false)
    const message = ref('')

    const updateMemberActivate = async () => {
      const payload = { memberID: memberID.value }

      try {
        const response = await dispatch('validate/updateMemberActivate', { payload })

        const data = response.data
        if (data.code === 200) {
          success.value = true
          message.value = '會員啟用成功'
          // window.setTimeout(() => location.replace("/login"), 2000);
        } else {
          success.value = false
          message.value = data.errMsg
          // window.setTimeout(() => location.replace("/login"), 2000);
        }
      } catch (error) {
        success.value = false
        message.value = '發生系統錯誤，未送出資料'
      }

      // // this.$refs?.loading.setLoading(true)
      // axios.get('/member/activate/' + '') // send ajax
      // // axios.get('/member/activate/' + [[${ userID }]]) //send ajax
      //   .then(function (response) { // success
      //     const res = response.data
      //     // console.log(res)
      //     if (res.code === 200) {
      //       // vm.$refs?.loading.setLoading(false)
      //       // showSuccessMsg('會員啟用成功')
      //       window.setTimeout(() => location.replace('/index'), 2000)
      //     } else {
      //       // vm.$refs?.loading.setLoading(false)
      //       // showErrorMsg(res.errMsg)
      //       window.setTimeout(() => location.replace('/index'), 2000)
      //     }
      //   })
      //   .catch(function (error) { // error
      //     console.log(error)
      //     // vm.$refs?.loading.setLoading(false)
      //     // showErrorMsg('發生系統錯誤，未送出資料')
      //     window.setTimeout(() => location.replace('/index'), 2000)
      //   })
    }

    await updateMemberActivate()

    return {
      message, success
    }
  }
}
</script>
<style lang="scss" module>

</style>
